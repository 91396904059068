import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { FaPhoneAlt, FaFacebook,FaLine ,FaMapMarkedAlt  } from "react-icons/fa";


class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='ติดต่อเรา' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Contact Top Area  */}
                <div className="contact-area ptb--50 bg_color--5">
                    <div className="contact-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title mb--30 text-center">
                                        <h3 className="title">ติดต่อสอบถามเราได้ทุกช่องทาง</h3>
                                        <p className="description">เรายินดีพร้อมให้บริการ</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="address">
                            <div className="ptb--40 bg_color--1">
                                <div className="container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <img src="/assets/images/contact/map-1.webp" alt="ห้างทองอุไรศิลป์ สาขา1" />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <h4>ห้างทองอุไรศิลป์ สาขา 1 เยื้องธนาคารกรุงศรียโสธร</h4>
                                                <p>266 ถนน อุทัยรามมฤทธิ์ ในเมือง เมืงอง ยโสธร 35000</p>
                                                <p><b>เวลาทำการ จันทร์ – เสาร์ 08.00 น. – 17.00 น.</b></p>
                                                <a href="tel:+6645 712 548" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 712 548</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/uraisilpyaso" class="button mt--15"><FaFacebook className="icon" />Facebook</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/%40wxs4060f" class="button mt--15"><FaLine className="icon" />Line</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/SCnw5WTjTMVwUUaEA" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ptb--40 bg_color--3">
                                <div className="container">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <img src="/assets/images/contact/map-2.webp" alt="ห้างทองอุไรศิลป์ สาขา2 " />
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                <h4>ห้างทองอุไรศิลป์ สาขา 2 ตรงข้ามโรงพยาบาลยโสธร</h4>
                                                <p>106 5 ตำบล เขื่องคำ เมือง ยโสธร 35000</p>
                                                <p><b>เวลาทำการ จันทร์ – เสาร์ 08.00 น. – 17.00 น.</b></p>
                                                <a href="tel:+6645 971 313" class="button mt--10"><FaPhoneAlt className="icon" />โทร 045 971 313</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/uraisilpyaso2" class="button mt--15"><FaFacebook className="icon" />Facebook</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://line.me/R/ti/p/%40wxs4060f" class="button mt--15"><FaLine className="icon" />Line</a>
                                                <a target="_blank" rel="noopener noreferrer" href="https://maps.app.goo.gl/qGWpLBRejZACYKtJ8" class="button mt--15"><FaMapMarkedAlt className="icon" />แผนที่ Google Map</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Contact Top Area  */}
                </div>


                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </React.Fragment>
        )
    }
}
export default Contact