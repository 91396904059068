import React, { Component } from "react";
import { Link } from 'react-router-dom';

const ProductMenuList1_image = <img src="/assets/images/product/productmenu-01.webp" alt="ทองคำ" />;
const ProductMenuList2_image = <img src="/assets/images/product/productmenu-02.webp" alt="ทองคำ" />;
const ProductMenuList3_image = <img src="/assets/images/product/productmenu-03.webp" alt="ทองคำ" />;
const ProductMenuList4_image = <img src="/assets/images/product/productmenu-04.webp" alt="ทองคำ" />;
const ProductMenuList5_image = <img src="/assets/images/product/productmenu-05.webp" alt="ทองคำ" />;
const ProductMenuList6_image = <img src="/assets/images/product/productmenu-06.webp" alt="ทองคำ" />;
const ProductMenuList8_image = <img src="/assets/images/product/productmenu-08.webp" alt="ทองคำ" />;
const ProductMenuList10_image = <img src="/assets/images/product/productmenu-10.webp" alt="ทองคำ" />;
const ProductMenuList11_image = <img src="/assets/images/product/productmenu-11.webp" alt="ทองคำ" />;
const ProductMenuList12_image = <img src="/assets/images/product/productmenu-12.webp" alt="ทองคำ" />;


const ProductMenuList = [
    {
        image: ProductMenuList1_image,
        link: '/ring',
        title: 'แหวน'
    },
    {
        image: ProductMenuList2_image,
        link: '/necklace',
        title: 'สร้อยคอ'
    },
    {
        image: ProductMenuList3_image,
        link: '/bracelet',
        title: 'สร้อยข้อมือ'
    },
    {
        image: ProductMenuList4_image,
        link: '/bangle',
        title: 'กำไลแขน'
    },
    {
        image: ProductMenuList5_image,
        link: '/pendant',
        title: 'จี้'
    },
    {
        image: ProductMenuList6_image,
        link: '/earring',
        title: 'ต่างหู'
    },
    {
        image: ProductMenuList8_image,
        link: '/children-bracelet',
        title: 'สร้อยคอ สร้อยแขน',
        titleTwo: 'กำไลข้อมือเด็ก',
    },
    {
        image: ProductMenuList10_image,
        link: '/silver',
        title: 'เงินแท้/นากแท้',
    },
    {
        image: ProductMenuList11_image,
        link: '/jewelry99',
        title: 'ทองรูปพรรณ 99.99%',
    },
    {
        image: ProductMenuList12_image,
        link: '/made-order',
        title: 'งานสั่งทำ',
    },
]

class ProductMenu extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="product-menu-wrapper">
                    <div className="container">
                        <div className="row">
                            {ProductMenuList.map((value, index) => (
                                <div className="block col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                                    <div className="im_product-menu">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to={value.link}>
                                                    {value.image}
                                                </Link>
                                            </div>


                                            <div className="content">
                                                <div className="inner">
                                                    <div className="product_heading">
                                                        <h4 className="title">{value.title}<br/>{value.titleTwo}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link className="transparent_link" to={value.link}></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default ProductMenu;