import React, { useState, useEffect } from 'react';

const GoldAPI = () => {
    const [thaiDateTime, setThaiDateTime] = useState({ date: '', time: '' });
    const [barGoldBuyPrice, setBarGoldBuyPrice] = useState(null);
    const [barGoldSellPrice, setBarGoldSellPrice] = useState(null);
    const [barGoldBuyChange, setBarGoldBuyChange] = useState(null);

    const [ornamentalGoldBuyPrice, setOrnamentalGoldBuyPrice] = useState(null);
    const [ornamentalGoldSellPrice, setOrnamentalGoldSellPrice] = useState(null);
    const [ornamentalGoldBuyChange, setOrnamentalGoldBuyChange] = useState(null);

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const fetchGoldPrice = async () => {
            try {
                const response = await fetch('https://apicheckprice.huasengheng.com/api/values/getprice/');
                if (!response.ok) {
                    throw new Error('กำลังโหลด...');
                }
                const data = await response.json();

                const now = new Date();
                const thaiDateString = now.toLocaleDateString('th-TH', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timeZone: 'Asia/Bangkok',
                });
                const thaiTimeString = now.toLocaleTimeString('th-TH', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: 'Asia/Bangkok',
                    hour12: false,
                });
                setThaiDateTime({ date: thaiDateString, time: thaiTimeString });


                setBarGoldBuyPrice(data[1].Buy);
                setBarGoldSellPrice(data[1].Sell);
                setBarGoldBuyChange(data[1].BuyChange);

                setOrnamentalGoldBuyPrice(data[2].Buy);
                setOrnamentalGoldSellPrice(data[2].Sell);
                setOrnamentalGoldBuyChange(data[2].BuyChange);

            } catch (error) {
                console.error('Error fetching gold price:', error);
            }
        };

        const timer = setInterval(() => {
            setTime(new Date());
          }, 1000);

        fetchGoldPrice();
        const intervalId = setInterval(fetchGoldPrice, 60000);
        return () => clearInterval(intervalId,timer);


        
          
    }, []);

    // Function to determine sign
    const getSign = (difference) => {
        return difference > 0 ? '+' : difference < 0 ? '-' : '';
    };

    const timeString = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'Asia/Bangkok',
        hour12: false
      }).format(time);

    return (
        <div className='gold-api-area'>
            <div className='gold-price-container'>
                <div className='section-title'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                <div className="logo-title row">
                                    <img className="logo" src="/assets/images/logo/logo-one.webp" alt="" />
                                    <h4 className='title'>ราคาทองคำ</h4>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                <p className='description'>ประจำวันที่: {thaiDateTime.date}</p>
                                <p className='description'>เวลา: {timeString} น.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <table className='gold-price-table'>
                    <thead>
                        <tr className='list'>
                            <th className='text-color-type'>ประเภททอง</th>
                            <th className='text-color-buy'>รับซื้อ</th>
                            <th className='text-color-sell'>ขายออก</th>
                            <th className='text-color-diff'>เปลี่ยนแปลง</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                ทองคำแท่ง 96.5%
                            </td>
                            <td className='text-price'>
                                {barGoldBuyPrice}
                            </td>
                            <td className='text-price'>
                                {barGoldSellPrice}
                            </td>
                            <td className='text-price'>
                                {getSign(barGoldBuyChange)}{Math.abs(barGoldBuyChange)}
                            </td>
                        </tr>
                        <tr>
                            <td>ทองรูปพรรณ</td>
                            <td className='text-price'>
                                {ornamentalGoldBuyPrice}
                            </td>
                            <td className='text-price'>
                                {ornamentalGoldSellPrice}
                            </td>
                            <td className='text-price'>
                                {getSign(ornamentalGoldBuyChange)}{Math.abs(ornamentalGoldBuyChange)}

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>




    );
};
export default GoldAPI;