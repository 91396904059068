import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import MenuBar from '../../blocks/MenuBar';


const imageList = [
    { image: '01', bigImage: '/assets/images/product/necklace/necklace-01.webp', },
    { image: '02', bigImage: '/assets/images/product/necklace/necklace-02.webp', },
    { image: '03', bigImage: '/assets/images/product/necklace/necklace-03.webp', },
    { image: '04', bigImage: '/assets/images/product/necklace/necklace-04.webp', },
    { image: '05', bigImage: '/assets/images/product/necklace/necklace-05.webp', },
    { image: '06', bigImage: '/assets/images/product/necklace/necklace-06.webp', },
    { image: '07', bigImage: '/assets/images/product/necklace/necklace-07.webp', },
    { image: '08', bigImage: '/assets/images/product/necklace/necklace-08.webp', },
    { image: '09', bigImage: '/assets/images/product/necklace/necklace-09.webp', },
    { image: '10', bigImage: '/assets/images/product/necklace/necklace-10.webp', },
    { image: '11', bigImage: '/assets/images/product/necklace/necklace-11.webp', },
    { image: '12', bigImage: '/assets/images/product/necklace/necklace-12.webp', },
    { image: '13', bigImage: '/assets/images/product/necklace/necklace-13.webp', },
    { image: '14', bigImage: '/assets/images/product/necklace/necklace-14.webp', },
    { image: '15', bigImage: '/assets/images/product/necklace/necklace-15.webp', },
    { image: '16', bigImage: '/assets/images/product/necklace/necklace-16.webp', },
    { image: '17', bigImage: '/assets/images/product/necklace/necklace-17.webp', },
    { image: '18', bigImage: '/assets/images/product/necklace/necklace-18.webp', },
    { image: '19', bigImage: '/assets/images/product/necklace/necklace-19.webp', },

    { image: '20', bigImage: '/assets/images/product/necklace/necklace-20.webp', },
    { image: '21', bigImage: '/assets/images/product/necklace/necklace-21.webp', },
    { image: '22', bigImage: '/assets/images/product/necklace/necklace-22.webp', },
    { image: '23', bigImage: '/assets/images/product/necklace/necklace-23.webp', },
    { image: '24', bigImage: '/assets/images/product/necklace/necklace-24.webp', },
    { image: '25', bigImage: '/assets/images/product/necklace/necklace-25.webp', },
    { image: '26', bigImage: '/assets/images/product/necklace/necklace-26.webp', },
    { image: '27', bigImage: '/assets/images/product/necklace/necklace-27.webp', },
    { image: '28', bigImage: '/assets/images/product/necklace/necklace-28.webp', },
    { image: '29', bigImage: '/assets/images/product/necklace/necklace-29.webp', },

    { image: '30', bigImage: '/assets/images/product/necklace/necklace-30.webp', },
    { image: '31', bigImage: '/assets/images/product/necklace/necklace-31.webp', },
    { image: '32', bigImage: '/assets/images/product/necklace/necklace-32.webp', },
    { image: '33', bigImage: '/assets/images/product/necklace/necklace-33.webp', },
    { image: '34', bigImage: '/assets/images/product/necklace/necklace-34.webp', },
    { image: '35', bigImage: '/assets/images/product/necklace/necklace-35.webp', },
    { image: '36', bigImage: '/assets/images/product/necklace/necklace-36.webp', },
    { image: '37', bigImage: '/assets/images/product/necklace/necklace-37.webp', },
    { image: '38', bigImage: '/assets/images/product/necklace/necklace-38.webp', },
    { image: '39', bigImage: '/assets/images/product/necklace/necklace-39.webp', },

    { image: '40', bigImage: '/assets/images/product/necklace/necklace-40.webp', },
    { image: '41', bigImage: '/assets/images/product/necklace/necklace-41.webp', },
    { image: '42', bigImage: '/assets/images/product/necklace/necklace-42.webp', },
    { image: '43', bigImage: '/assets/images/product/necklace/necklace-43.webp', },
    { image: '44', bigImage: '/assets/images/product/necklace/necklace-44.webp', },
    { image: '45', bigImage: '/assets/images/product/necklace/necklace-45.webp', },
    { image: '46', bigImage: '/assets/images/product/necklace/necklace-46.webp', },
    { image: '47', bigImage: '/assets/images/product/necklace/necklace-47.webp', },
    { image: '48', bigImage: '/assets/images/product/necklace/necklace-48.webp', },
    { image: '49', bigImage: '/assets/images/product/necklace/necklace-49.webp', },
    { image: '50', bigImage: '/assets/images/product/necklace/necklace-50.webp', },

    { image: '51', bigImage: '/assets/images/product/necklace/necklace-51.webp', },
    { image: '52', bigImage: '/assets/images/product/necklace/necklace-52.webp', },
    { image: '53', bigImage: '/assets/images/product/necklace/necklace-53.webp', },
    { image: '54', bigImage: '/assets/images/product/necklace/necklace-54.webp', },
    { image: '55', bigImage: '/assets/images/product/necklace/necklace-55.webp', },
    { image: '56', bigImage: '/assets/images/product/necklace/necklace-56.webp', },
    { image: '57', bigImage: '/assets/images/product/necklace/necklace-57.webp', },
    { image: '58', bigImage: '/assets/images/product/necklace/necklace-58.webp', },
    { image: '59', bigImage: '/assets/images/product/necklace/necklace-59.webp', },
    { image: '60', bigImage: '/assets/images/product/necklace/necklace-60.webp', },

]



class Necklace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='สร้อยคอ' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'สร้อยคอ'} />

                <div className="product-area">
                    <div className="ring-area ptb--50">
                        <div className="container">

                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                    <div className="section-title mb--20">
                                        <h3 className="title">สร้อยคอ</h3>
                                        <p className="description">สร้อยคอทองรูปพรรณ ดีไซน์ทันสมัย ลวดลายสวยงาม ออกแบบโดยช่างผู้เชี่ยวชาญ</p>
                                    </div>
                                    <div className="img mobile-hidden">
                                        <img src={`/assets/images/product/necklace/necklace-01.webp`} alt="แหวนทองรูปพรรณ" />
                                    </div>
                                    <div className="row">
                                        {imageList.map((value, index) => (
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" key={index}>
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={imageList[tab1].bigImage}
                                                        nextSrc={imageList[(tab1 + 1) % imageList.length]}
                                                        prevSrc={imageList[(tab1 + imageList.length - 1) % imageList.length]}
                                                        animationDuration={3000}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}

                                                        onMovePrevRequest={() =>
                                                            this.setState({
                                                                tab1: (tab1 + imageList.length - 1) % imageList.length,
                                                            })
                                                        }
                                                        onMoveNextRequest={() =>
                                                            this.setState({
                                                                tab1: (tab1 + 1) % imageList.length,
                                                            })
                                                        }
                                                    />
                                                )}
                                                <div className="item-img-static">
                                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                                        <div className="img-static">
                                                            <div className="thumbnail-inner">
                                                                <div className="thumbnail">
                                                                    <img src={`/assets/images/product/necklace/necklace-${value.image}.webp`} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt_mobile--30">
                                    <MenuBar />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}
                <Footer />
            </React.Fragment>
        )
    }
}
export default Necklace;