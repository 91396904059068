import React, { Component } from "react";
import { FaInstagram, FaFacebookF, FaLine } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
import { FiX, FiMenu } from "react-icons/fi";
import { Link } from 'react-router-dom';

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/uraisilpyaso' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/uraisilp_yaso/' },
//     { Social: <SiTiktok  />, link: 'https://www.tiktok.com/@uraisilp' },
//     { Social: <FaLine  />, link: 'https://line.me/R/ti/p/%40wxs4060f' },
// ]
class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() { }
    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenudesktop').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;
        if (logo === 'light') {
            logoUrl = <img src="/assets/images/logo/logo-light.webp" alt="ห้างทองอุไรศิลป์" />;
        } else {
            logoUrl = <img src="/assets/images/logo/logo.webp" alt="ห้างทองอุไรศิลป์" />;
        }

        return (
            <header className={`header-area header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href="/">{logoUrl}
                            </a>
                            {/* <a href={this.props.homeLink}>
                                {logoUrl}
                            </a> */}
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu">
                                <li className="mobile-contact mb--30 mt--10">
                                    <a href="/">
                                        <img className="w-100" src="/assets/images/logo/logo.webp" alt="ห้างทองอุไรศิลป์" />
                                    </a>
                                    {/* <img className="w-100" src="/assets/images/logo/logo.webp" alt="ห้างทองอุไรศิลป์" /> */}
                                </li>
                                <li><Link to="/" >หน้าแรก</Link></li>

                                {/* Desktop */}
                                <li className="has-droupdown desktop-show">
                                    <Link to="product" >ทองรูปพรรณ ▾</Link>
                                    <ul className="submenudesktop">
                                        <li><Link to="/ring">แหวน</Link></li>
                                        <li><Link to="/necklace">สร้อยคอ</Link></li>
                                        <li><Link to="/bracelet">สร้อยข้อมือ</Link></li>
                                        <li><Link to="/bangle">กำไลแขน</Link></li>
                                        <li><Link to="/pendant">จี้</Link></li>
                                        <li><Link to="/earring">ต่างหู</Link></li>
                                        <li><Link to="/children-bracelet">สร้อยคอ สร้อยแขน <br /> กำไลข้อมือเด็ก</Link></li>
                                        <li><Link to="/silver">เงินแท้/นากแท้</Link></li>
                                        <li><Link to="/jewelry99">ทองรูปพรรณ 99.99%</Link></li>
                                        <li><Link to="/made-order">งานสั่งทำ</Link></li>
                                    </ul>
                                </li>


                                {/* MOBILE */}
                                <li className="has-droupdown mobile-show">
                                    <Link to="product" >ทองรูปพรรณ</Link>
                                    <ul className="submenumobile">
                                        <li><Link to="/ring">แหวน</Link></li>
                                        <li><Link to="/necklace">สร้อยคอ</Link></li>
                                        <li><Link to="/bracelet">สร้อยข้อมือ</Link></li>
                                        <li><Link to="/bangle">กำไลแขน</Link></li>
                                        <li><Link to="/pendant">จี้</Link></li>
                                        <li><Link to="/earring">ต่างหู</Link></li>
                                        <li><Link to="/children-bracelet">สร้อยคอ สร้อยแขน <br /> กำไลข้อมือเด็ก</Link></li>
                                        <li><Link to="/silver">เงินแท้/นากแท้</Link></li>
                                        <li><Link to="/jewelry99">ทองรูปพรรณ 99.99%</Link></li>
                                        <li><Link to="/made-order">งานสั่งทำ</Link></li>
                                    </ul>
                                </li>

                                <li><Link to="goldbar" >ทองแท่ง/ทองแผ่น</Link>
                                </li>
                                <li><Link to="goldsaver" >ออมทอง</Link>
                                </li>
                                <li><Link to="about" >เกี่ยวกับเรา</Link>
                                </li>
                                <li><Link to="contact" >ติดต่อเรา</Link>
                                </li>


                                <li className="mobile-contact mt--50">
                                    <div>
                                        <div className="social-share-inner">
                                            <ul className="social-share-herder color-black d-flex justify-content-center liststyle">

                                                <li className="facebook"><a href="https://www.facebook.com/uraisilpyaso" aria-label="facebook"><FaFacebookF /></a></li>
                                                <li className="instagram"><a href="https://www.instagram.com/uraisilp_yaso/" aria-label="instagram"><FaInstagram /></a></li>
                                                <li className="tiktok"><a href="https://www.tiktok.com/@uraisilp" aria-label="tiktok"><SiTiktok /></a></li>
                                                <li className="line"><a href="https://line.me/R/ti/p/%40wxs4060f" aria-label="line"><FaLine /></a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                            {/* <Scrollspy className="mainmenu" items={['home', 'about', 'service', 'portfolio', 'contact']} currentClassName="is-current" offset={-200}>
                                <li><a href="#home">หน้าแรก</a></li>
                                <li><a href="#about">ทองรูปพรรณ</a></li>
                                <li><a href="#service">ออมทอง</a></li>
                                <li><a href="#portfolio">ตราสารอนุพันธ์</a></li>
                                <li><a href="about">เกี่ยวกับเรา</a></li>
                                <li><a href="contact">ติดต่อเรา</a></li>
                            </Scrollspy> */}



                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner d-none d-sm-block ml--50">
                            <ul className="social-share-herder color-black d-flex justify-content-start liststyle">

                                <li className="facebook"><a href="https://www.facebook.com/uraisilpyaso" aria-label="facebook"><FaFacebookF /></a></li>
                                <li className="instagram"><a href="https://www.instagram.com/uraisilp_yaso/" aria-label="instagram"><FaInstagram /></a></li>
                                <li className="tiktok"><a href="https://www.tiktok.com/@uraisilp" aria-label="tiktok"><SiTiktok /></a></li>
                                <li className="line"><a href="https://line.me/R/ti/p/%40wxs4060f" aria-label="line"><FaLine /></a></li>

                            </ul>
                        </div>
                        {/* <div className="header-btn">
                            <a className="btn-default btn-border btn-opacity" target="_blank" href="https://themeforest.net/checkout/from_item/31405042?license=regular">
                                <span>buy Now</span>
                            </a>
                        </div> */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;