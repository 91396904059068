import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from 'react-helmet'

class PageHelmet extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} - Uraisin ห้างทองอุไรศิลป์</title>
                    <meta name="description" content="ห้างทองอุไรศิลป์" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta property="og:image" content="/assets/images/logo/logo.webp" />
                    <meta name="keywords" content="ร้านทอง,ร้านทองยโสธร,ห้างทองอุไรศิลป์,ทองคำแท่ง,ทองรูปพรรณ,ซื้อขายทองคำ,ราคาทองคำวันนี้,สร้อยคอทองคำ,แหวนทองคำ,จิวเวลรี่ทองคำ,อัญมณี,การลงทุนทองคำ" />

                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
