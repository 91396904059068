import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { slideSlick } from "./script";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { FaInstagram, FaLine } from "react-icons/fa";
import { SiTiktok } from "react-icons/si";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ShortcutMenu from "../component/HomeLayout/homeOne/ShortcutMenu";
import GoldAPI from "../blocks/GoldAPI";
import GoldCharts from "../blocks/GoldCharts";

import { portfolioSlick2 } from "./script";

const Portfolio_image1 = <img src="/assets/images/portfolio/portfolio-1.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-2.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-3.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-4.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-5.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-6.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-7.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-8.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-9.webp" alt="ลูกค้าร้านทอง" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-10.webp" alt="ลูกค้าร้านทอง" />;


const AccessoriesList1_image = <img src="/assets/images/product/productmenu-01.webp" alt="แหวนทอง" />;
const AccessoriesList2_image = <img src="/assets/images/product/productmenu-02.webp" alt="สร้อยคอทอง" />;
const AccessoriesList3_image = <img src="/assets/images/product/productmenu-03.webp" alt="สร้อยข้อมือทอง" />;
const AccessoriesList4_image = <img src="/assets/images/product/productmenu-04.webp" alt="กำไลแขนทอง" />;
const AccessoriesList5_image = <img src="/assets/images/product/productmenu-05.webp" alt="จี้ทอง" />;
const AccessoriesList6_image = <img src="/assets/images/product/productmenu-06.webp" alt="ต่างหูทอง" />;
const AccessoriesList8_image = <img src="/assets/images/product/productmenu-08.webp" alt="สร้อยคอ สร้อยแขน กำไลข้อมือเด็ก" />;
const AccessoriesList10_image = <img src="/assets/images/product/productmenu-10.webp" alt="เงินแท้/นากแท้ทอง" />;
const AccessoriesList11_image = <img src="/assets/images/product/productmenu-11.webp" alt="ทองรูปพรรณ" />;
const AccessoriesList12_image = <img src="/assets/images/product/productmenu-12.webp" alt="งานสั่งทำ" />;


const AccessoriesList = [
    {
        image: AccessoriesList1_image,
        link: '/ring',
        title: 'แหวน'
    },
    {
        image: AccessoriesList2_image,
        link: '/necklace',
        title: 'สร้อยคอ'
    },
    {
        image: AccessoriesList3_image,
        link: '/bracelet',
        title: 'สร้อยข้อมือ'
    },
    {
        image: AccessoriesList4_image,
        link: '/bangle',
        title: 'กำไลแขน'
    },
    {
        image: AccessoriesList5_image,
        link: '/pendant',
        title: 'จี้'
    },
    {
        image: AccessoriesList6_image,
        link: '/earring',
        title: 'ต่างหู'
    },
    {
        image: AccessoriesList8_image,
        link: '/children-bracelet',
        title: 'สร้อยคอ สร้อยแขน',
        titleTwo: 'กำไลข้อมือเด็ก',
    },
    {
        image: AccessoriesList10_image,
        link: '/silver',
        title: 'เงินแท้/นากแท้',
    },
    {
        image: AccessoriesList11_image,
        link: '/jewelry99',
        title: 'ทองรูปพรรณ 99.99%',
    },
    {
        image: AccessoriesList12_image,
        link: '/made-order',
        title: 'งานสั่งทำ',
    },
]

const SlideList = [
    { bgImage: 'bg_image--1' },
    { bgImage: 'bg_image--2' },
    { bgImage: 'bg_image--3' },
    { bgImage: 'bg_image--4' },
    { bgImage: 'bg_image--5' }
]

const PortfolioList2 = [
    { image: Portfolio_image1, },
    { image: Portfolio_image2, },
    { image: Portfolio_image3, },
    { image: Portfolio_image4, },
    { image: Portfolio_image5, },
    { image: Portfolio_image6, },
    { image: Portfolio_image7, },
    { image: Portfolio_image8, },
    { image: Portfolio_image9, },
    { image: Portfolio_image10, },
]


class Startup extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    render() {
        return (
            <Fragment>
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                // Start Single Slider 
                                <div className="img" >
                                    <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    </div>
                                </div>
                                // End Single Slider 
                            ))}
                        </Slider>
                    </div>
                </div >
                {/* End Slider Area   */}

                <div className="mt--40 mb--10 bg_color--1">
                    <div className="container">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">

                                    <a href="https://facebook.com/uraisilpyaso" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/contact/fb-contact-1.webp" alt="ห้างทองอุไรศิลป์1" /></a>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <a href="https://facebook.com/uraisilpyaso2" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/contact/fb-contact-2.webp" alt="ห้างทองอุไรศิลป์ สาขา2" /></a>
                                </div>
                            </div>
                            <div className="row mt--30">
                                <div className="col-12">
                                    <a href="https://line.me/R/ti/p/%40wxs4060f" target="_blank" rel="noopener noreferrer"><img className="w-100" rel="noopener noreferrer" src="/assets/images/contact/line-contact-1.webp" alt="ห้างทองอุไรศิลป์" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >



                {/* Start API Graph */}
                <div className="api-graph-area mt--40 mb--10 bg_color--1">
                    <div className="container">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt--10">
                                    <GoldAPI />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt--10">
                                    <GoldCharts />
                                </div>
                            </div>
                        </div>
                    </div>


                </div >
                {/* End API Graph */}

                {/* Start Shortcut Menu */}
                <div className="shortcut-menu-area bg_color--1">
                    <div className="portfolio-sacousel-inner mb--5">
                        <ShortcutMenu />
                    </div>
                </div>
                {/* End Shortcut Menu */}


                {/* Product List Menu */}
                <div className="product-area">
                    <div className="product-menu-wrapper">
                        <div className="container">
                            <div className="row">
                                {AccessoriesList.map((value, index) => (
                                    <div className="block col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="im_product-menu">
                                            <div className="thumbnail_inner">
                                                <div className="thumbnail">
                                                    <Link to={value.link}>
                                                        {value.image}
                                                    </Link>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <div className="product_heading">
                                                            <h4 className="title">{value.title}<br />{value.titleTwo}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to={value.link} aria-label="ห้างทองอุไรศิลป์"></Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* END Product List Menu */}

                <div className="rn-button-wrapper">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="row button-group mt--20 text-center">
                                <div className="col-lg-4">
                                    <a className="btn-default color-instagram module-border-instagram size-md" href="https://www.instagram.com/uraisilp_yaso/"><FaInstagram className="icon"/> Instagram</a>
                                </div>
                                <div className="col-lg-4">
                                    <a className="btn-default color-tiktok size-md" href="https://www.tiktok.com/@uraisilp"><SiTiktok className="icon"/> Tiktok</a>
                                </div>
                                <div className="col-lg-4">
                                    <a className="btn-default color-line size-md" href="https://line.me/R/ti/p/%40wxs4060f"><FaLine className="icon"/> Line</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* Start About Area */}
                <div className="about-area ptb--40 mt--50 bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--20 align-items-center">
                                <div className="col-lg-6 col-md-12">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-1.webp" alt="ประวัติห้างทองอุไรศิลป์" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="about-inner inner">
                                        <div className="header-left d-flex align-items-center">
                                            <div className="logo">
                                                <img src="/assets/images/logo/logo.webp" alt="ห้างทองอุไรศิลป์" />
                                            </div>
                                        </div>
                                        <div className="section-title mt--20">
                                            {/* <h2 className="title">ห้างทองอุไรศิลป์</h2> */}
                                            <p className="description">เปิดมาแล้วเป็นเวลาเกือบ 70 ปี จากรุ่นหนึ่งผู้บุกเบิก สืบทอดมาถึงรุ่นสองผู้จุดประกายความสำเร็จ และได้ดำเนินธุรกิจต่อเนื่องมาจนถึงรุ่นที่สามในปัจจุบันนี้ พวกเราเริ่มต้นจากการขายทองรูปพรรณ โดยเน้นไปที่เนื้อทองและคุณภาพเป็นหลัก ผ่านยุคสมัยของการซื้อเพื่อความสวยงามและสะสม ทองคำเริ่มมีการพัฒนามาเป็น การซื้อขายทองคำแท่งเพื่อการลงทุน ห้างทองอุไรศิลป์จึงได้พัฒนาการบริการเพื่อให้ลูกค้าได้รับความสะดวกมากขึ้น โดยมีราคาอ้างอิงทองคำแท่งของสมาคมค้าทองคำแห่งประเทศไทยได้ตลอดเวลา</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}
                <div className="service-area ptb--50 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="title">จำหน่ายทองรูปพรรณ-ทองแท่ง เพรชแท้ เงินแท้ นากแท้ บริการส่ง</h3>
                                    <h4 className="title">มีใบรับประกัน ทองคำแท้จากเยาวราชกรุงเทพ คุณภาพตามมาตรฐาน สคบ.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <img className="w-100" src="/assets/images/about/about-14.webp" alt="จำหน่ายทองรูปพรรณ" />
                </div>

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--40 bg_color--1" id="portfolio">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2 className="title">ขอขอบพระคุณลูกค้าทุกท่านที่ใช้บริการร้านเรา</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--30 mt_sm--30">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList2.map((value, index) => (
                                    <div className="im_portfolio" key={index}>
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                    {value.image}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />
            </Fragment >
        )
    }
}
export default Startup;