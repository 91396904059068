import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../common/Breadcrumb";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import MenuBar from '../../blocks/MenuBar';

const imageList = [
    { image: '01', bigImage: '/assets/images/product/bangle/bangle-01.webp', },
    { image: '02', bigImage: '/assets/images/product/bangle/bangle-02.webp', },
    { image: '03', bigImage: '/assets/images/product/bangle/bangle-03.webp', },
    { image: '04', bigImage: '/assets/images/product/bangle/bangle-04.webp', },
    { image: '05', bigImage: '/assets/images/product/bangle/bangle-05.webp', },
    { image: '06', bigImage: '/assets/images/product/bangle/bangle-06.webp', },
    { image: '07', bigImage: '/assets/images/product/bangle/bangle-07.webp', },
    { image: '08', bigImage: '/assets/images/product/bangle/bangle-08.webp', },
    { image: '09', bigImage: '/assets/images/product/bangle/bangle-09.webp', },
    { image: '10', bigImage: '/assets/images/product/bangle/bangle-10.webp', },
    { image: '11', bigImage: '/assets/images/product/bangle/bangle-11.webp', },
    { image: '12', bigImage: '/assets/images/product/bangle/bangle-12.webp', },
    { image: '13', bigImage: '/assets/images/product/bangle/bangle-13.webp', },
    { image: '14', bigImage: '/assets/images/product/bangle/bangle-14.webp', },
    { image: '15', bigImage: '/assets/images/product/bangle/bangle-15.webp', },
    { image: '16', bigImage: '/assets/images/product/bangle/bangle-16.webp', },
    { image: '17', bigImage: '/assets/images/product/bangle/bangle-17.webp', },
    { image: '18', bigImage: '/assets/images/product/bangle/bangle-18.webp', },
    { image: '19', bigImage: '/assets/images/product/bangle/bangle-19.webp', },
    { image: '20', bigImage: '/assets/images/product/bangle/bangle-20.webp', },
    { image: '21', bigImage: '/assets/images/product/bangle/bangle-21.webp', },
    { image: '22', bigImage: '/assets/images/product/bangle/bangle-22.webp', }

]



class Ring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <React.Fragment>
                <PageHelmet pageTitle='กำไลแขน' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />
                <Breadcrumb title={'กำไลแขน'} />

                <div className="product-area">
                    <div className="ring-area ptb--50">
                        <div className="container">

                            <div className="row">
                                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                    <div className="section-title mb--20">
                                        <h3 className="title">กำไลแขน</h3>
                                        <p className="description">กำไลแขนทองรูปพรรณ ดีไซน์ทันสมัย ลวดลายสวยงาม ออกแบบโดยช่างผู้เชี่ยวชาญ</p>
                                    </div>
                                    <div className="img mobile-hidden">
                                        <img src={`/assets/images/product/bangle/bangle-01.webp`} alt="กำไลแขน" />
                                    </div>
                                    <div className="row">
                                        {imageList.map((value, index) => (
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12" key={index}>
                                                {isOpen && (
                                                    <Lightbox
                                                        mainSrc={imageList[tab1].bigImage}
                                                        nextSrc={imageList[(tab1 + 1) % imageList.length]}
                                                        prevSrc={imageList[(tab1 + imageList.length - 1) % imageList.length]}
                                                        animationDuration={500}
                                                        onCloseRequest={() => this.setState({ isOpen: false })}

                                                        onMovePrevRequest={() =>
                                                            this.setState({
                                                                tab1: (tab1 + imageList.length - 1) % imageList.length,
                                                            })
                                                        }
                                                        onMoveNextRequest={() =>
                                                            this.setState({
                                                                tab1: (tab1 + 1) % imageList.length,
                                                            })
                                                        }
                                                    />
                                                )}
                                                <div className="item-img-static">
                                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                                        <div className="img-static">
                                                            <div className="thumbnail-inner">
                                                                <div className="thumbnail">
                                                                    <img src={`/assets/images/product/bangle/bangle-${value.image}.webp`} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt_mobile--30">
                                    <MenuBar />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                {/* Start Back To Top */}
                < div className="backto-top" >
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div >
                {/* End Back To Top */}
                <Footer />
            </React.Fragment>
        )
    }
}
export default Ring;